<template>
	<div>
		<el-dialog
			title="操作数据"
			:visible.sync="open"
			width="600px"
			@close="commitOnClose"
		>
			<el-table stripe v-loading="loading" :data="dataList">
				<el-table-column
					label="SN编号"
					prop="sn"
					min-width="120"
					:show-overflow-tooltip="true"
				>
				</el-table-column>
				<el-table-column
					label="活动"
					prop="activityName"
					min-width="140"
					:show-overflow-tooltip="true"
				/>
			</el-table>
			<!-- 分页 -->
			<Pagination
				v-show="total > 0"
				:total="total"
				:page.sync="pageNo"
				:limit.sync="pageSize"
				@pagination="getDetailListFn"
			/>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="cancel">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { TerminalApi } from "@/api";
export default {
	name: "CustomForm",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		isAdd: {
			type: Boolean,
			default: true
		},
		onFresh: {
			type: Boolean,
			default: false
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			loading: false,
			open: false,
			pageNo: 1,
			pageSize: 10,
			total: 0,
			dataList: []
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
		}
	},
	created() {},
	mounted() {},
	methods: {
		MyBatchNo() {},
		// 获取列表
		async getDetailListFn(batchNo) {
			this.loading = true;
			let result = await TerminalApi.terminalInfo.getTerminalDetailList(
				this.pageNo,
				this.pageSize,
				batchNo,
				this.queryParams
			);
			console.log("getTerminalDetailList----->99", result);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data || [];
		},
		cancel() {
			this.commitOnClose();
		},
		commitOnClose() {
			this.$emit("on-close");
		}
	}
};
</script>

<style scoped></style>
